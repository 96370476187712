<template>
  <div class="basic-form">
    <div class="card">
      <p class="card-item-title">{{ $t('claim.BasicInformationTitle') }}</p>
      <div class="card-item-container">
        <div class="item-flex"> <p>{{ $t('claim.ReportIDLabel') }}</p><p class="info-value">{{ formData.reportUid }}</p></div>
        <div class="item-flex"> <p>{{ $t('claim.ReporterNameLabel') }}</p><p class="info-value">{{ formData.reporter }}</p></div>
        <div class="item-flex"> <p>{{ $t('claim.ReporterEmailLabel') }}</p><p class="info-value">{{ formData.reporterEmail }}</p></div>
        <div class="item-flex"> <p>{{ $t('claim.ReporterMobileLabel') }}</p><p class="info-value">{{ formData.reporterMobile }}</p></div>
        <div class="item-flex"> <p>{{ $t('claim.ReportStatusLabel') }}</p><p class="info-value">{{ statusOptions[formData.status] || '-' }}</p></div>
      </div>
    </div>

    <div class="card">
      <p class="card-item-title">{{ $t('claim.InsuranceInformationTitle') }}</p>
      <div class="card-item-container">
        <div class="item-flex"> <p>{{ $t('claim.PolicyNoLabel') }}</p><p class="info-value">{{ formData.policyNo || '-' }}</p></div>
        <div class="item-flex"> <p>{{ $t('claim.SlipNoLabel') }}</p><p class="info-value">{{ formData.slipUid || '-' }}</p></div>
        <div class="item-flex"> <p>{{ $t('claim.InsuredNameLabel') }}</p><p class="info-value">{{ formData.insuredName || '-' }}</p></div>
        <div class="item-flex"> <p>{{ $t('claim.ProductNameLabel') }}</p><p class="info-value">{{ formData.productName || '-' }}</p></div>
        <!-- <div class="item-flex"> <p>{{ $t('claim.EffectivePeriodLabel') }}</p><p class="info-value">{{ formData.endTime | formatDate }}</p></div> -->
        <div class="item-flex"> <p>{{ $t('claim.EffectivePeriodLabel') }}</p><p class="info-value">{{ formatDateTime(formData.endTime,'', '', 'ss') }}</p></div>
      </div>
    </div>

    <div class="card">
      <p class="card-item-title">{{ $t('claim.InsuredObject') }}</p>
      <div class="card-item-container">
        <div
          v-for="(item, index) in formData.insuredInfo"
          :key="index"
          class="item-flex"
        > <p>{{ item.label }}</p><p class="info-value">{{ item.value || '-' }}</p></div>
      </div>
    </div>
  </div>

</template>

<script>
import { ebReportBasicInfo } from '@/views/claim/api/index.js'
import { formatDateTime } from '@fu/core'
export default {
  components: {},
  props: {},
  data() {
    return {
      statusOptions: ['-', 'Handling', 'Approved', 'Completed', 'Declined'],
      formData: {},
      formatDateTime
    }
  },
  activated() {
    this.init()
  },
  methods: {
    init() {
      this.formData = {}
      this.ebReportBasicInfo()
    },
    async ebReportBasicInfo() {
      const { reportUid } = this.$route.query
      const res = await ebReportBasicInfo({ reportUid })
      // if (!res.registerUid) return
      const details = { ...res }
      details.insuredInfo = []
      if (details.insuredObject && details.insuredObjectFieldName) {
        const value = Object.keys(details.insuredObjectFieldName).map(key => {
          return {
            label: details.insuredObjectFieldName[key],
            value: details.insuredObject[key]
          }
        })
        details.insuredInfo = value || []
      }
      this.formData = details || {}
    }
  }
}
</script>
<style lang="scss" scoped>
.basic-form {
  padding: 10px 15px;
  .card-item-title {
    font-size: 16px;
    color: #EE0A24;
    margin-top: 10px;
  }
  .item-flex {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EBEDF0;
    padding: 10px 0px;
    .info-value {
      width: 50%;
      text-align: right;
    }
  }
  .card-item-title {

  }
}
</style>
